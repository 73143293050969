<template>
  <div>
    <div style="margin: 20px 0 0px 20px">
      <MyBreadcrumb></MyBreadcrumb>
    </div>
    <div class="role-main">
      <div class="margin-bottom-10 top">
        <!-- <Button type="primary" @click="add">{{$t('production_stat_add')}}</Button> -->
        <!-- <div class="search_input">
          <Input v-model="keyword"
                  @on-enter="changeKeyword"
                  @on-search="changeKeyword"
                  :placeholder="$t('production_stat_search_tip')"
                  clearable
                  search
                  :enter-button="true"
            />
          </div> -->
      </div>

      <Table border :columns="columns" :loading="loading" :data="data"></Table>
      <div class="page">
        <Page :total="total" @on-change="changePage" @on-page-size-change="changePageSize" :current="page" show-total show-sizer show-elevator></Page>
      </div>
      <DeleModal :status="deleModalStatus" :txt="$t('production_stat_dele_tip')" :loading="modalLoading" @cancel="cancelDele" @confirm="confirmDele"></DeleModal>

      <Modal
          v-model="modalStatus"
          :title="modalTit"
          @on-cancel="cancel"
      >
        <Form :model="formItem" ref="formCustom" :label-width="80" :rules="ruleValidate">
          <FormItem :label="$t('production_stat_table_site')" prop="site">
            <Input :disabled="type == 'check'" v-model="formItem.site" :placeholder="$t('production_stat_add_site_tip')"></Input>
          </FormItem>
          <FormItem :label="$t('production_stat_table_expire')" prop="expire">
            <Input :disabled="type == 'check'"  v-model="formItem.expire" :placeholder="$t('production_stat_add_expire_tip')"></Input>
          </FormItem>

        </Form>
        <div slot="footer">
          <Button @click="cancel">取消</Button>
          <Button type="primary" @click="ok" :loading="addBtnLoading" v-if="type != 'check'" >确定</Button>
        </div>
      </Modal>
    </div>
  </div>

</template>

<script>
import util from '@/utils/tools.js';
import DeleModal from '../../../components/deleteModal.vue';
export default {
  name: "role",
  data(){
    return{
      page:1,
      pageSize:10,
      total:0,
      data: [],
      loading:false,
      keyword:'',
      columns: [
        // {
        //   title: 'ID',
        //   key: 'id',
        // },
        {
          title: this.$t('production_stat_usename'),
          //key: 'nickname',
          render: (h, para) => {
                if (para.row.user) {
                  return h('span', para.row.user.nickname)
                }
            },
        },
        {
          title: this.$t('production_stat_projectname'),
          //key: 'link',
          render: (h, para) => {
                if (para.row.project) {
                  return h('span', para.row.project.name)
                }
            },
        },
        {
            title: this.$t('production_stat_category'),
            //key: 'show_count',
            render: (h, para) => {
                if (para.row.project) {
                  return h('span', para.row.project.category.name)
                }
            },
        },
        {
            title: this.$t('production_stat_batch'),
            //key: 'show_count',
            render: (h, para) => {
                if (para.row.task) {
                    return h('span', util.base64decode(para.row.task.batch.name))
                }
            },
        },
        {
            title: this.$t('production_stat_step'),
            //key: 'show_count',
            render: (h, para) => {
                if (para.row.task) {
                  return h('span', para.row.task.step.name)
                }
            },
        },
        {
            title: this.$t('production_stat_work_time'),
            key: 'work_time',
            align: 'center',
        },
        {
            title: this.$t('production_stat_work_count'),
            key: 'work_count',
            align: 'center',
        },
        {
            title: this.$t('production_stat_allow_count'),
            key: 'allow_count',
            align: 'center',
        },
        {
            title: this.$t('production_stat_refuse_count'),
            key: 'refuse_count',
        },
        {
            title: this.$t('production_stat_reset_count'),
            key: 'reset_count',
        },
        {
            title: this.$t('user_created_at'),
            key: 'created_at',
            render: (h, para) => {
                if (para.row.created_at) {
                  return h('span', util.timeFormatter(new Date(+para.row.created_at*1000), 'MM-dd hh:mm'))
                }
            },
        },
        {
            title: this.$t('user_updated_at'),
            key: 'updated_at',
            render: (h, para) => {
                if (para.row.updated_at) {
                  return h('span', util.timeFormatter(new Date(+para.row.updated_at*1000), 'yyyy-MM-dd hh:mm'))
                }
            },
        },
        // {
        //     title: this.$t('production_stat_last_login_time'),
        //     key: 'end_time',
        //     align: 'center',
        //     maxWidth: 150,
        //     sortable: 'custom',
        //     render: (h, para) => {
        //         return h('span',
        //             util.timeFormatter(
        //                 new Date(+para.row.last_login_time * 1000),
        //                 'yyyy- hh:mm'
        //             )
        //         );
        //     }
        // },
        /*{
          title: this.$t('production_stat_operation'),
          render: (h, params) => {
            return h('div', [
              // h('Button', {
              //   props: {
              //     type: 'primary',
              //     size: 'small'
              //   },
              //   style: {
              //     marginRight: '5px'
              //   },
              //   on: {
              //     click: () => {
              //       this.copylink(params.row)
              //     }
              //   }
              // }, this.$t('production_stat_copylink')),
            //   h('Button', {
            //     props: {
            //       type: 'error',
            //       size: 'small'
            //     },
            //     on: {
            //       click: () => {
            //         this.delete(params.row);
            //       }
            //     }
            //   }, this.$t('system_dele'))
            ]);
          }
        }*/
      ],
      deleteComfirmModal:true,
      deleModalStatus:false,
      modalLoading:false,
      curDeleData:{},
      modalStatus:false,
      formItem:{
        id:0,
        site:'',
        expire:''
      },
      ruleValidate: {
        site: [
          { required: true, message: this.$t('production_stat_add_site_tip'), trigger: 'blur' }
        ],
        expire: [
          { required: true, message: this.$t('production_stat_add_expire_tip'), trigger: 'blur' },
        ],
      },
      addBtnLoading:false,
      type:'',
      modalTit:'',
    }
  },
  components:{
    DeleModal
  },
  mounted(){
    this.getList();
  },
  watch: {
    keyword () {
      if (!this.keyword) {
        this.changeKeyword();
      }
    }
  },
  methods:{
    changeKeyword () {
      this.page = 1;
      this.getList();
    },
    add(){
      this.modalTit = this.$t('production_stat_add');
      this.type = '';
      this.formItem = {
        id:0,
        site:'',
        expire:''
      };
      this.modalStatus = true;
    },
    check(data){
      this.modalTit = this.$t('production_stat_check');
      this.type = 'check';
      this.formItem = {
        id:0,
        site:data.site,
        expire:data.expire
      };
      this.modalStatus = true;
    },
    edit(data){
      this.modalTit = this.$t('production_stat_edit');
      this.type = 'edit';
      this.formItem = {
        id:data.id,
        site:data.site,
        expire:data.expire
      };
      this.modalStatus = true;
    },
    getList(){
      this.loading = true;
      let params = {
        page:this.page,
        pageSize:this.pageSize,
        keyword: this.keyword,
      };
      this.api.production.userstatsList(params).then((res)=>{
        this.loading = false;
        if(!res.list.length && this.page > 1){
          this.page = this.page - 1;
          this.getList();
          return;
        }
        this.data = res.list;
        this.total = Number(res.count);
      }).catch((err)=>{
        this.loading = false;
      })
    },
    delete(data){
      this.curDeleData = data;
      this.deleModalStatus = true;
    },
    cancelDele(){
      this.deleModalStatus = false;
    },
    confirmDele(){
      let data = {
        name:this.curDeleData.name
      }
      this.modalLoading = true;
      this.api.site.invitationDelete(data).then((res)=>{
        this.modalLoading = false;
        this.deleModalStatus = false;
        this.getList();
      }).catch((err)=>{
        this.modalLoading = false;
      });
    },
    changePage(page){
      console.log(page,'changePage')
      this.page = page;
      this.getList();
    },
    changePageSize(pageSize){
      this.pageSize = pageSize;
      this.page = 1;
      this.getList();
    },
    ok(){
      this.$refs.formCustom.validate((valid) => {
        if (valid) {
          let data = {
            id:this.formItem.id,
            site:this.formItem.site,
            expire:this.formItem.expire
          };
          this.addBtnLoading = true;
          if(this.formItem.id){
            this.api.site.invitationUpdate(data).then((res)=>{
              this.addBtnLoading = false;
              this.modalStatus = false;
              this.getList();
            }).catch((err)=>{
              this.addBtnLoading = false;
            })
          }else{
            this.api.site.invitationCreate(data).then((res)=>{
              this.addBtnLoading = false;
              this.modalStatus = false;
              this.page = 1;
              this.getList();
            }).catch((err)=>{
              this.addBtnLoading = false;
            })
          }

        }else{
          console.log(this.modalStatus)
        }
      })
    },
    cancel(){
      this.modalStatus = false;
      this.$refs.formCustom.resetFields();
    },
  }
}
</script>

<style scoped lang="scss">
.role-main{
  margin: 20px;
  padding:20px;
  background: #FFFFFF;
  box-shadow: 0px 2px 12px 0px rgba(189,189,189,0.4100);
  border-radius: 4px;

  .margin-bottom-10{
    margin-bottom: 20px;
  }
  .top{
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .page{
    margin-top: 20px;
    text-align: right;
  }
}
</style>
